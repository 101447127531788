<template>
  <v-dialog v-model="dialogInterno" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="text-h6">
          <strong>ATENÇÃO!</strong> Ainda não recebemos o seu certificado
          digital.
        </span>
      </v-card-title>

      <v-card-text class="text-subtitle-1">
        <span>
          O certificado digital é necessário para que possamos realizar as
          transmissões das suas obrigações contábeis, sem ele não conseguimos
          prestar nossos serviços.
        </span>
        <br />
        <br />
        <span>
          Você pode contratar o seu eCNPJ tipo A1 com a certificadora de sua
          preferência e precisa ser renovado anualmente
        </span>
        <br />
        <br />
        <strong>
          Após a contratação e videoconferência com a Certificadora, favor
          entrar em contato com nossa equipe, ou subir o certificado (com a
          senha cadastrada) diretamente em
          <a href="https://app.simplificador.com.br/certificado">
            https://app.simplificador.com.br/certificado
          </a>
        </strong>
        <br />
        <br />
        Atenciosamente,
        <br />
        Equipe Simplificador
        <br />
        <v-card-actions class="text-right">
          <v-row justify="end">
            <v-btn class="text-right" color="success" @click="accept()">
              estou ciente
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { authComputed } from '@state/helpers'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialogInterno: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      hasCertificateActive: 'certificates/hasCertificateActive',
    }),
  },
  methods: {
    accept() {
      this.dialogInterno = false
    },
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('certificates/getCertificates')
    this.dialogInterno = !this.hasCertificateActive
    this.loading = false
  },
}
</script>
